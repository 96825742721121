<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <base-card
        :with-style="false"
        :title="`${packet.complained ? '' : 'Ajukan'} Komplain`"
        :description="`${packet.complained ? '' : 'Ajukan'} komplain barang pada koli`"
      >
        <div
          class="flex flex-col justify-between border-t pt-5"
          style="height: 500px"
        >
          <div>
            <div v-if="packet.complained" class="flex flex-col items-end gap-2">
              <div class="flex justify-end flex-wrap gap-2">
                <base-badge
                  v-for="(product, i) in complainedProducts"
                  :key="i"
                  color="indigo"
                  >{{ product.productCode }} x{{ product.qty }}</base-badge
                >
              </div>
              <div class="bg-gray-100 px-4 py-3 rounded-lg fit text-sm text-gray-900">{{ packet.complainDescription }}</div>
              <p class="text-xs text-gray-600">{{ packet.complainSubmittedAt | formatDate }}</p>
            </div>
          </div>
          <form v-if="!packet.complained" class="space-y-4" @submit.prevent="onSubmit">
            <div class="flex flex-wrap gap-2">
              <base-badge
                v-for="(product, i) in products"
                :key="i"
                color="indigo"
                >{{ product.productCode }} x{{ product.qty }}</base-badge
              >
            </div>
            <base-input
              fullwidth
              inset
              type="text"
              label="Masukkan keterangan"
              placeholder="Keterangan komplain barang"
              v-model="description"
            >
              <template #append>
                <base-button
                  type="submit"
                  size="sm"
                  class="absolute right-3 top-4"
                >
                  <Icon icon="heroicons:paper-airplane-solid" class="h-4 w-4" />
                </base-button>
              </template>
            </base-input>
          </form>
        </div>
      </base-card>
    </base-modal>

    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport';
import BaseModal from '@/components/base/BaseModal.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    Teleport,
  },
  props: {
    visible: Boolean,
    products: Array,
    packet: Object,
  },
  emits: ['close', 'complained'],
  data() {
    return {
      description: null,
      loading: false,
    };
  },
  computed: {
    complainedProducts() {
      return this.packet.products
        .filter(product => product.complained_product_qty > 0)
        .map(product => ({
          productCode: product.product_code,
          qty: product.complained_product_qty
        }))
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onOpened() {
      this.description = null;
    },
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/packets/${this.packet.uuid}/-actions/complain`,
        {
          method: 'patch',
          data: {
            description: this.description,
            products: this.products.map((product) => ({
              id: product.productId,
              qty: product.qty,
            })),
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.createAlert({
          status: 'success',
          data: 'Komplain berhasil diajukan',
        });

        this.$emit('complained')
      }

      this.loading = false;
    },
  },
};
</script>
